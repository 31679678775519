<template>
  <body-simple>
    <template #header>
      <h2 class="text-xl font-bold">
        <img
          src="@/assets/images/3387286_payment_purse_shopping_wallet_icon.png"
          class="h-20 ml-2 inline-block"
        />
        کیف پول
      </h2>
      <p class="mt-3">
        کیف پول یکپارچه تلیشا. می‌توانید کیف پول خود را شارژ کنید، همچنین درامد های خود را
        از آن برداشت کنید.
      </p>
    </template>
    <template #default>
      <div class="grid grid-cols-2 md:grid-cols-4 gap-4 w-full">
        <div class="bg-blueGray-100 border border-blueGray-200 rounded-md p-5">
          <div class="">شارژ :</div>
          <div class="text-xl text-left" dir="ltr">
            {{ $number.format($store.state.user.wallet) }}
          </div>
        </div>
        <div class="bg-blueGray-100 border border-blueGray-200 rounded-md p-5">
          <div class="">شارژ جایزه :</div>
          <div class="text-xl text-left" dir="ltr">
            {{ $number.format($store.state.user.bonus) }}
          </div>
        </div>
        <div class="bg-blueGray-100 border border-blueGray-200 rounded-md p-5">
          <div class="">درآمد :</div>
          <div class="text-xl text-left" dir="ltr">
            {{ $number.format($store.state.user.earn) }}
          </div>
        </div>
        <div class="bg-blueGray-100 border border-blueGray-200 rounded-md p-5">
          <div class="">موجودی :</div>
          <div class="text-xl text-left" dir="ltr">
            {{
              $number.format(
                $store.state.user.wallet +
                  $store.state.user.bonus +
                  $store.state.user.earn -
                  $store.state.user.debit
              )
            }}
          </div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 w-full mt-5">
        <Button
          :to="{ name: 'WalletCharge', params: { id: this.$store.state.user?.id } }"
          color="cyan"
        >
          شارژ کیف پول
        </Button>
        <Button
          :to="{ name: 'Checkout', params: { id: this.$store.state.user?.id } }"
          color="cyan"
        >
          تسویه حساب
        </Button>
      </div>
    </template>
  </body-simple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Button from "@/components/Button.vue";

export default {
  components: {
    // MnrText,
    BodySimple,
    Button,
  },
  computed: {},
};
</script>
